document.addEventListener("DOMContentLoaded", () => {

  const activeClass = 'active';

  const closeTab = key => {

    const item = document.querySelector(`.acf-field-group[data-key="${key}"]`)

    item.classList.remove( activeClass )

  }

  const openTab = key => {

    const item = document.querySelector(`.acf-field-group[data-key="${key}"]`)
    item.classList.add( activeClass )

    setTimeout( () => {
      scrollOnTab( item );
    },250);
    
  }

  const closeAllTabs = () => {
    const current = document.querySelector('.acf-field-group.active')
    if( current ) closeTab( current.dataset.key )

  }

  const scrollOnTab = item => {
   
    const offset = ( item.getBoundingClientRect().top + window.scrollY ) - 200;
  
    window.scroll({
      top: offset,
      behavior: 'smooth'
    });
  }

  const group = document.querySelectorAll('.acf-field-group');
  Array.from( group ).map( item => {

    const label = item.querySelector('.acf-label label');
    document.addEventListener('click', e => {
      if( e.target == label ) {

        if( !item.classList.contains( activeClass ) ) {
          closeAllTabs();
          openTab( item.dataset.key );
        } else {
          closeTab( item.dataset.key );
        }

      }
    })

  })

});